<template>
    <div>
        <div class="mb-4">
            <div
                class="field grid"
                v-for="field in fields"
                v-if="field.key !== 'createUser' || !value.hasOwnProperty('id')"
            >
                <label class="col-fixed text-700 mt-1 w-4">
                    {{ field.label }}
                </label>
                <template v-if="field.key === 'separator'">
                    <div class="col">
                        <div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
                    </div>
                </template>
                <template v-else-if="field.key === 'createUser'">
                    <div class="col">
                        <Checkbox
                            :binary="true"
                            v-model="value[field.key]"
                        />
                    </div>
                </template>
                <template v-else-if="field.key === 'gender'">
                    <div class="col">
                        <gender-select v-model="value['gender']"/>
                    </div>
                </template>
                <template v-else>
                    <div class="col">
                        <InputText
                            class="w-full"
                            type="text"
                            required
                            v-model="value[field.key]"
                        />
                        <errors :errors="errors[field.key]"/>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import InputText from "primevue/inputtext/InputText";
    import Textarea from "primevue/textarea";
    import EntitySelect from "@/components/form/EntitySelect";
    import Value from '@/mixin/Value';
    import GenderSelect from "@/components/form/GenderSelect";
    import Errors from "@/components/form/Errors";
    import Checkbox from "primevue/checkbox/Checkbox";

    export default {
        mixins: [Value],
        components: {
            Errors,
            GenderSelect,
            EntitySelect,
            InputText,
            Textarea,
            Checkbox
        },
        data() {
            return {
                fields: [
                    {
                        label: 'Anrede',
                        key: 'gender',
                    },
                    {
                        label: 'Vorname',
                        key: 'firstName',
                    },
                    {
                        label: 'Nachname',
                        key: 'lastName',
                    },
                    {
                        key: 'separator'
                    },
                    {
                        label: 'E-Mail-Adresse',
                        key: 'email',
                    },
                    {
                        label: 'Telefonnummer',
                        key: 'phone',
                    },
                    {
                        label: 'Frontend-Benutzer anlegen',
                        key: 'createUser'
                    }
                ],
            }
        }
    }
</script>
