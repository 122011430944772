<template>
    <div>
        <div class="mb-4">
            <div v-if="showMainContact">
                <div class="text-xl uppercase mb-4">Unternehmensdaten</div>
            </div>
            <div class="field grid" v-for="field in fields">
                <label class="col-fixed text-700 mt-1 w-4">
                    {{ field.label }}
                </label>
                <template v-if="field.key === 'separator'">
                    <div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
                </template>
                <template v-else-if="field.key === 'priority'">
                    <div class="col">
                        <Dropdown
                            class="w-full"
                            :options="priorityOptions"
                            v-model="value[field.key]"
                            optionLabel="label"
                            optionValue="key"
                        />
                    </div>
                </template>
                <template v-else-if="field.key === 'location'">
                    <div class="col-2">
                        <InputText
                            class="w-full"
                            type="text"
                            required
                            v-model="value['zip']"
                        />
                        <errors :errors="errors['zip']"/>
                    </div>
                    <div class="col">
                        <InputText
                            class="w-full"
                            type="text"
                            required
                            v-model="value['city']"
                        />
                        <errors :errors="errors['city']"/>
                    </div>
                </template>
                <template v-else>
                    <div class="col">
                        <InputText
                            class="w-full"
                            type="text"
                            required
                            v-model="value[field.key]"
                        />
                        <errors :errors="errors[field.key]"/>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import InputText from "primevue/inputtext/InputText";
    import Textarea from "primevue/textarea";
    import EntitySelect from "@/components/form/EntitySelect";
    import Value from '@/mixin/Value';
    import UpdateForm from "@/components/data/person/UpdateForm";
    import Errors from "@/components/form/Errors";
    import Dropdown from "primevue/dropdown";

    export default {
        props: {
            type: {
                type: String,
                default: () => 'provider',
            }
        },
        mixins: [Value],
        components: {
            Errors,
            EntitySelect,
            InputText,
            Textarea,
            UpdateForm,
            Dropdown,
        },
        computed: {
            priorityOptions: function () {
                let options = [];
                let plainOptions = this.$store.getters.settings.model.company[this.type].priority;
                Object.keys(plainOptions).forEach((item) => {
                    options.push({
                        label: plainOptions[item],
                        key: item,
                    })
                });

                return options;
            },
        },
        data() {
            return {
                fields: [
                    {
                        label: 'Status',
                        key: 'priority'
                    },
                    {
                        key: 'separator'
                    },
                    {
                        label: 'Name',
                        key: 'name',
                    },
                    {
                        key: 'separator'
                    },
                    {
                        label: 'Straße',
                        key: 'street',
                    },
                    {
                        label: 'Stadt',
                        key: 'location',
                    },
                    {
                        label: 'Webseite',
                        key: 'url',
                    },
                ],
            }
        }
    }
</script>
