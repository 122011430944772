<template>
    <SelectButton v-model="value" :options="genders" option-label="label" option-value="key"/>
</template>

<script>
    import SelectButton from 'primevue/selectbutton';

    export default {
        props: {
            value: {
                type: [String],
                required: true,
            }
        },
        watch: {
            value: function(newVal) {
                this.$emit('input', newVal);
            }
        },
        data() {
            return {
                genders: [
                    {
                        key: 'm',
                        label: 'Herr',
                    },
                    {
                        key: 'f',
                        label: 'Frau',
                    },
                    {
                        key: 'd',
                        label: 'Divers',
                    }
                ]
            }
        },
        components: {
            SelectButton
        }
    }
</script>
